var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-row',{attrs:{"gutter":24,"type":"flex"}},[_c('div',{staticClass:"snackbar",class:{ active: _vm.isActive }},[_vm._v(" "+_vm._s(_vm.message)+" ")]),_c('a-col',{staticClass:"mb-24",attrs:{"span":24}},[_c('a-card',{staticClass:"header-solid h-full",attrs:{"bordered":false,"bodyStyle":{ paddingTop: 0 }},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('h5',{staticClass:"font-semibold text-center"},[_vm._v(" "+_vm._s(_vm.promotion.title)+" Update ")])]},proxy:true}])},[_c('a-form',{staticClass:"game-upload-form",attrs:{"id":"components-form-demo-normal-login","form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('a-row',{attrs:{"gutter":24,"type":"flex"}},[_c('a-col',{staticClass:"mb-24",attrs:{"span":24,"md":24}},[_c('a-form-item',{staticClass:"mb-10"},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'title',
                  {
                    initialValue: _vm.promotion.title,
                    rules: [
                      {
                        required: false,
                        message: 'Please input  title!',
                      } ],
                  } ]),expression:"[\n                  'title',\n                  {\n                    initialValue: promotion.title,\n                    rules: [\n                      {\n                        required: false,\n                        message: 'Please input  title!',\n                      },\n                    ],\n                  },\n                ]"}],attrs:{"placeholder":"Title"}})],1),_c('a-form-item',{staticClass:"mb-10"},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'body',
                  {
                    initialValue: _vm.promotion.body,
                    rules: [
                      { required: true, message: 'Please input  body!' } ],
                  } ]),expression:"[\n                  'body',\n                  {\n                    initialValue: promotion.body,\n                    rules: [\n                      { required: true, message: 'Please input  body!' },\n                    ],\n                  },\n                ]"}],attrs:{"placeholder":"Body"}})],1),_c('a-form-item',{staticClass:"mb-10"},[_c('div',{staticStyle:{"display":"flex","flex-wrap":"wrap","justify-content":"space-between","align-items":"center","gap":"5px"}},[_c('div',[_c('label',[_vm._v("Published: ")]),_c('a-switch',{staticStyle:{"margin-left":"6px"},model:{value:(_vm.isPublished),callback:function ($$v) {_vm.isPublished=$$v},expression:"isPublished"}})],1)])]),_c('a-form-item',[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'event_id',
                  {
                    rules: [
                      {
                        required: false,
                        message: 'Please select Event Feature !',
                      } ],
                  } ]),expression:"[\n                  'event_id',\n                  {\n                    rules: [\n                      {\n                        required: false,\n                        message: 'Please select Event Feature !',\n                      },\n                    ],\n                  },\n                ]"}],attrs:{"placeholder":"Select Event Feature"}},_vm._l((_vm.featureList),function(value,id){return _c('a-select-option',{key:value.id},[_vm._v(" "+_vm._s(value.name)+" ")])}),1)],1),_c('a-form-item',{staticClass:"mb-10"},[_c('div',{staticStyle:{"display":"flex"}},[_c('div',{staticClass:"file-input"},[_c('label',[_vm._v(" Image")]),_c('a-input',{attrs:{"type":"file"},on:{"change":function($event){return _vm.imageUploadChange($event)}}})],1),_c('img',{attrs:{"src":_vm.promotion.image,"alt":"image","width":"100","height":"100"}})])])],1)],1),_c('a-form-item',[_c('a-button',{staticClass:"login-form-button",attrs:{"type":"primary","block":"","html-type":"submit"}},[(_vm.confirmLoading)?_c('a-spin'):_vm._e(),_vm._v(" Update ")],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }