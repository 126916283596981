<template>
  <a-row :gutter="24" type="flex">
    <div class="snackbar" :class="{ active: isActive }">
      {{ message }}
    </div>
    <a-col :span="24" class="mb-24">
      <a-card
        :bordered="false"
        class="header-solid h-full"
        :bodyStyle="{ paddingTop: 0 }"
      >
        <template #title>
          <h5 class="font-semibold text-center">
            {{ promotion.title }} Update
          </h5>
        </template>
        <a-form
          id="components-form-demo-normal-login"
          :form="form"
          class="game-upload-form"
          @submit="handleSubmit"
        >
          <a-row :gutter="24" type="flex">
            <a-col :span="24" :md="24" class="mb-24">
              <a-form-item class="mb-10">
                <a-input
                  v-decorator="[
                    'title',
                    {
                      initialValue: promotion.title,
                      rules: [
                        {
                          required: false,
                          message: 'Please input  title!',
                        },
                      ],
                    },
                  ]"
                  placeholder="Title"
                >
                </a-input>
              </a-form-item>
              <a-form-item class="mb-10">
                <a-textarea
                  v-decorator="[
                    'body',
                    {
                      initialValue: promotion.body,
                      rules: [
                        { required: true, message: 'Please input  body!' },
                      ],
                    },
                  ]"
                  placeholder="Body"
                >
                </a-textarea>
              </a-form-item>
              <a-form-item class="mb-10">
                <div
                  style="
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    align-items: center;
                    gap: 5px;
                  "
                >
                  <div>
                    <label>Published: </label>
                    <a-switch
                      v-model:checked="isPublished"
                      style="margin-left: 6px"
                    />
                  </div>
                </div>
              </a-form-item>
              <a-form-item>
                <a-select
                  placeholder="Select Event Feature"
                  v-decorator="[
                    'event_id',
                    {
                      rules: [
                        {
                          required: false,
                          message: 'Please select Event Feature !',
                        },
                      ],
                    },
                  ]"
                >
                  <a-select-option v-for="(value, id) in featureList" :key="value.id">
                    {{ value.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>

              <a-form-item class="mb-10">
                <div style="display: flex">
                  <div class="file-input">
                    <label> Image</label>
                    <a-input type="file" @change="imageUploadChange($event)">
                    </a-input>
                  </div>
                  <img
                    :src="promotion.image"
                    alt="image"
                    width="100"
                    height="100"
                  />
                </div>
              </a-form-item>
            </a-col>
          </a-row>
          <a-form-item>
            <a-button
              type="primary"
              block
              html-type="submit"
              class="login-form-button"
            >
              <a-spin v-if="confirmLoading" />
              Update
            </a-button>
          </a-form-item>
        </a-form>
      </a-card>
    </a-col>
  </a-row>
</template>

<script>
import { sendRequest } from "@/http/axios.method";
import event from "ant-design-vue/lib/_util/css-animation/Event";

export default {
  name: "GameUpdate",
  computed: {
    event() {
      return event;
    },
  },
  data() {
    return {
      config: {
        Authorization: "Bearer " + this.$store.state.authUser.token,
      },
      promotionId: this.$route.query.id,
      confirmLoading: false,
      promotion: [],
      isPublished: false,
      profileImage: "",
      isActive: false,
      message: "",
      featureList: [],
    };
  },
  beforeCreate() {
    // Creates the form and adds to it component's "form" property.
    this.form = this.$form.createForm(this, { name: "new_game_upload" });
  },
  created() {
    this.getPromotionDetails();
    this.getEventList();
  },
  methods: {
    getPromotionDetails() {
      sendRequest(
        "get",
        "admin/promotion-details",
        { id: this.promotionId },
        this.config
      )
        .then((response) => {
          if (response.success) {
            console.log(response.data);
            this.promotion = response.data;
            this.isPublished =
              this.promotion.published_status === 1 ? true : false;
          } else {
            if (response.status_code === 401) {
              this.$store.dispatch("logout");
              this.$router.push("/login");
            }
          }
        })
        .catch(() => {})
        .finally(() => {});
    },
    getEventList() {
      sendRequest("get", "admin/getEvents", {}, this.config)
        .then((response) => {
          if (response.success) {
            let data = response.data;
            this.featureList = data.map(function (content, label) {
              return {
                id: content.id,
                name: content.event_name,
              };
            });
          } else {
            if (response.status_code === 401) {
              this.$store.dispatch("logout");
              this.$router.push("/login");
            }
          }
        })
        .catch(() => {})
        .finally(() => {});
    },

    imageUploadChange(event, type) {
      this.profileImage = event.target.files[0];
    },
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          this.confirmLoading = true;
          let form_data = new FormData();
          let configHeader = {
            "content-type": "multipart/form-data",
            Authorization: "Bearer " + this.$store.state.authUser.token,
          };
          let data = {
            id: this.promotionId,
            published_status: this.isPublished === true ? 1 : 0,
            title: values.title,
            body: values.body,
            event_id: values.event_id ? values.event_id : '',
          };
          for (let key in data) {
            form_data.append(key, data[key]);
          }
          form_data.append("image", this.profileImage);

          console.log(form_data);

          sendRequest("post", "admin/update-promotion", form_data, configHeader)
            .then((response) => {
              if (response.success) {
                this.confirmLoading = false;
                this.$router.push("/promotion");
              } else {
                if (response.status_code === 401) {
                  this.$store.dispatch("logout");
                  this.$router.push("/login");
                }
              }
            })
            .catch(() => {
              this.confirmLoading = false;
            })
            .finally(() => {
              this.confirmLoading = false;
            });
        }
      });
    },
  },
};
</script>

<style scoped></style>
